'use strict';

/**
 * Introduces a delay for the specified number of milliseconds.
 *
 * @param {number} ms - The number of milliseconds to delay.
 * @returns {Promise<void>} A promise that resolves after the specified delay.
 */
export default async function delay(ms) {
  return new Promise(function (resolve) {
    setTimeout(resolve, ms);
  });
}
