<template>
  <section
    class="dashboard-nav-section bg-white rounded-lg shadow-md p-4 animate-fade-in mb-6"
  >
    <h2 class="text-xl font-medium text-red-800 mb-3">{{ title }}</h2>
    <div class="space-y-2">
      <slot></slot>
    </div>
  </section>
</template>

<style scoped>
.dashboard-nav-section :deep(a) {
  display: block;
  @apply text-gray-600;
  @apply hover:text-red-800;
}
</style>

<script>
export default {
  name: 'NavSection',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
