<template>
  <picture :class="classes">
    <source
      srcset="https://static.cogburnbros.com/logo.svg"
      type="image/svg+xml"
    />
    <img
      :class="classes"
      src="https://static.cogburnbros.com/logo.png"
      alt="Cogburn Bros"
    />
  </picture>
</template>

<script>
export default {
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
};
</script>
