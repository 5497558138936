'use strict';

let F = {};

F.getIdToken = async function () {
  if (F.idToken) {
    return F.idToken;
  }

  let resp = await globalThis
    .fetch('/api/authn/session/id_token', {
      method: 'POST',
    })
    .then(F._assertOk);

  let json = await resp.json();
  F.idToken = json.id_token;
  return F.idToken;
};

F.fetch = async function (url, options) {
  if (!options) {
    options = {};
  }

  // Set default headers
  if (!options.headers) {
    options.headers = {};
  }
  if (!options.headers['Content-Type']) {
    options.headers['Content-Type'] = 'application/json';
  }

  // Remove undefined headers
  Object.keys(options.headers).forEach(function (key) {
    if (options.headers[key] === undefined) {
      delete options.headers[key];
    }
  });

  let needsIdToken = !url.startsWith('/api/authn/');
  if (needsIdToken) {
    await F.getIdToken();
    Object.assign(options.headers, {
      Authorization: `Bearer ${F.idToken}`,
    });
  }

  // Make the fetch request
  let resp = await fetch(url, options).then(F._assertOk);

  return resp;
};

F._assertOk = async function (resp) {
  if (resp.ok) {
    return resp;
  }

  // if it's NOT ok, it's hopefully a well-formed error
  // but we really just don't know
  let err = new Error('unhandled error');
  let text = await resp.text();
  try {
    let data = JSON.parse(text);
    if (!data.code) {
      throw new Error('not well-formed');
    }
    // change to a handled error
    Object.assign(err, {
      message: data.message,
      code: data.code,
      status: resp.status,
      detail: data.detail,
    });
  } catch (_) {
    // maybe json, maybe not, but
    // some sort of irregural error
    Object.assign(err, {
      detail: text,
    });
  }

  throw err;
};

export default F;
