'use strict';

import '../../css/style.css';
import * as Vue from 'vue';
import Loading from '../components/loading.vue';
import AppHeader from './components/app-header.vue';
import Dashboard from './components/dashboard.vue';
import F from '../lib/fetch.js';
import delay from '../lib/delay.js';

const app = Vue.createApp({
  data: function () {
    return {
      showLoading: false,
      showApp: false,
      me: null,
    };
  },
  components: {
    Loading,
    AppHeader,
    Dashboard,
  },
  methods: {
    getMe: async function () {
      let vm = this;
      let resp = await F.fetch('/api/employees/me');
      let me = await resp.json();
      vm.me = me;
    },
    toSignin: function () {
      localStorage.setItem('returnTo', '/');
      localStorage.setItem('userNeedsSignIn', true);
      window.location.href = '/signin';
    },
    signOut: async function ($event) {
      if ($event) {
        $event.preventDefault();
      }
      let resp = await fetch('/api/authn/session', { method: 'DELETE' });
      if (resp.ok) {
        window.location.href = '/signin';
      }
    },
  },
  created: async function () {
    let vm = this;

    vm.showLoading = true;

    let idToken = await F.getIdToken().catch(function (err) {
      if (err.code === 'E_SESSION_INVALID') {
        return '';
      }
      throw err;
    });
    if (!idToken) {
      await delay(500);
      vm.toSignin();
      return;
    }

    await vm.getMe();

    Object.assign(vm, {
      showLoading: false,
      showApp: true,
    });
  },
});

app.directive('click-outside', {
  beforeMount(el, binding) {
    el.clickOutsideHandler = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.addEventListener('click', el.clickOutsideHandler);
  },
  unmounted(el) {
    document.removeEventListener('click', el.clickOutsideHandler);
  },
});

app.mount('#app');

app.config.errorHandler = async function (err, vm, info) {
  // Unhandled unauthorized request
  if (err.resp?.status === 401) {
    void (await vm.signOut());
    return;
  }

  console.log(err);

  // TODO Send error to an external service

  // TODO Alert that users should never see
  window.alert(
    'An unexpected error has occurred in the application. Please refresh and try again. If the error persists, contact support at app@cogburnbros.com for help.'
  );

  void (await vm.signOut());
};
